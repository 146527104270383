<template>
  <div class="video-player-item">
    <video-player :options="videoOptions" ref="myPlayer"></video-player>
  </div>
</template>

<script>
import { server, local } from "../js/variables.js";

export default {
  props: ['src', 'start'],
  components: {
      
  },
  data(){
    return {
      videoOptions: {
        source: {
          type: 'application/x-mpegURL',
          src: '',
          withCredentials: false,
          autoplay: false,
        },
        // live: true
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        start: 0,
      },
      timerEndVideo: '',
      stopTimerEndVideo: false,
    }
  },
  computed: {
    player() {
      return this.$refs.myPlayer.player;
    },
  },
  beforeDestroy () {
    clearInterval(this.timerEndVideo);
  },
  mounted() {
    let self = this;
    this.videoOptions.source.src = this.src;
    this.videoOptions.start = this.start;
    // this.endOfVideo();
    self.timerEndVideo = setInterval(function () {
      // console.log("tempo", self.$refs.myPlayer.player.cache_.duration, self.$refs.myPlayer.player.cache_.currentTime);
      self.$emit("player", self.$refs.myPlayer.player);
    }, 2000);
  },
  watch: {
  },
  methods: {
    changeValue(rating, value){
      if(rating.value == value){
        rating.value = value - 1;
      }else{
        rating.value = value;
      }
    },
    endOfVideo(){
      let self = this;
      if(!self.stopTimerEndVideo){
        // console.log("começou");
        self.timerEndVideo = setInterval(function () {
          // console.log("tempo", self.$refs.myPlayer.player.cache_.duration, self.$refs.myPlayer.player.cache_.currentTime);
          if(self.$refs.myPlayer.player.cache_.duration - self.$refs.myPlayer.player.cache_.currentTime < 5){
            // clearInterval(this.timerEndVideo);
            self.stopTimerEndVideo = true;
            self.$emit("showRating", self.$refs.myPlayer.player.cache_.currentTime);
            // console.log("funcionou antes");
          }
        }, 2000);
      }else{
        clearInterval(self.timerEndVideo);
      }
    },
    stopInterval(){

    }
  }
}
</script>

<style lang="scss">

.video-player-item {
  display: grid;

  .rating{
    position: absolute;
    background-color: white;
    border-radius: 10px;
    padding: 20px 30px;
    top: 175px;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 350px;

    h2{
      text-align: center;
      margin-bottom: 20px;
    }
    
    button{
      width: 160px;
      margin-top: 20px;
    }

    i{
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 600px) {
  
}
</style>